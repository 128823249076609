<template>
  <div class="test">
    <h3 class="title">
      警示行为
    </h3>
    <div class="box">
      <div
        class="test-title"
        v-html="warningStr"
      />
      <div class="tip">
        五大能区测评结束，测评过程中若有不确定选项，可返回上一步修改。然后再回到预警行为页提交，获取报告
      </div>
      <div class="test-answer">
        <div
          class="test-answer__item"
          @click="select(true)"
        >
          <span
            class="circle"
            :class="{select:value}"
          >
            <i class="el-icon-check" />
          </span> 是
        </div>
        <div
          class="test-answer__item"
          @click="select(false)"
        >
          <span
            class="circle"
            :class="{select:value===false}"
          >
            <i class="el-icon-check" />
          </span>否
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { waringBehavior, saveEvalWarning } from '@/api';

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    canViewReport: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      warningStr: '',
    };
  },
  created() {
    this.waringBehavior();
  },
  methods: {
    select(isSelect) {
      this.$emit('input', isSelect);
    },
    waringBehavior() {
      const params = {
        childrenBirthday: this.$route.query.childrenBirthday,
        evalRecordId: this.$route.query.evalRecordId
      };
      waringBehavior(params).then((res) => {
        if (res.code === 20000) {
          this.warningStr = res?.data || '-';
          this.$emit('update:canViewReport', true);
        } else {
          this.$message.warning(res.msg || '服务端异常，请联系管理员！');
        }
      });
    },
    saveEvalWarning(sucCb, CatchCb) {
      // if (!this.value) {
      //   sucCb();
      //   return;
      // }
      let warningBehavior = '';
      if (this.value) {
        warningBehavior = this.warningStr === '-' ? '' : this.warningStr;
      }
      const params = {
        evalRecordId: Number(this.$route.query.evalRecordId),
        warningBehavior
      };
      saveEvalWarning(params).then(sucCb, CatchCb);
    }
  },
};
</script>

<style lang="less" scoped>
.test{
  margin-bottom: 16px;
  .title{
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #333;
  }
  .border{
    border: 1px solid #E5E5E5;
    border-radius: 4px;
    overflow: hidden;
  }
  .box{
    .border;

    padding: 16px;
  }
  &-type{
    .border;

    display: flex;
    margin-bottom: 16px;
    &__title{
      margin-left: 17px;
    }
    .title{
      font-weight: 500;
      font-size: 16px;
      color: #333;
    }
    .text{
      font-size: 14px;
      color: #888;
    }
  }
  .pic img{
    height: 100%;
  }
  &-index{
    margin-bottom: 16px;
    font-size: 14px;
    color: #BDBDBD;
  }
  &-title{
    .border;

    padding: 15px;
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 14px;
    color: #333;
    line-height: 1.5;
  }
  &-text{
    font-size: 16px;
  }
  &-video{
    height: 218px;
    line-height: 218px;
    margin-bottom: 16px;
  }
  &-answer{
    &__item{
      .border;

      margin-bottom: 16px;
      padding: 8px 16px;
      cursor: pointer;
    }
    .circle{
      height: 24px;
      line-height: 24px;
      width: 24px;
      text-align: center;
      border: 1px solid #BDBDBD;
      border-radius: 100%;
      display: inline-block;
      margin-right: 16px;
      color: white;
      &.select{
        background: #85C6CE;
        border-color: #85C6CE;
      }
    }
  }
  .tip{
    font-size: 14px;
    color: #999;
    margin-bottom: 20px;
    line-height: 1.25;
  }
}
</style>
